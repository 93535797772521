
.modal {
  width: 100%;
  height: 0;
  position: fixed;
  bottom: 0;
  left: 0;
  z-index: 999;
  overflow: hidden;
  .transition(all .3s ease);

  &:before {
    content: '';
    background: @primary;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    position: absolute;
    .opacity(0.95);
  }

  &.modal-opened {
    height: 100%;
  }

  .modal-content {
    width: 90%;
    height: 80%;
    padding: 40px;
    position: absolute;
    top: 50%;
    left: 50%;
    z-index: 10;
    color: @white-clean;
    .translate(-50%, -50%);
  }

  .content-scroll {
    height: 100%;
    overflow: hidden scroll;

    &::-webkit-scrollbar {
      width: 12px; /* for vertical scrollbars */
      height: 12px; /* for horizontal scrollbars */
    }

    &::-webkit-scrollbar-track {
      background: rgba(0, 0, 0, 0.1);
    }

    &::-webkit-scrollbar-thumb {
      background: rgba(0, 0, 0, 0.5);
    }
  }

  .btn-close-modal {
    width: 38px;
    height: 38px;
    text-align: center;
    font-size: 38px;
    line-height: normal;
    font-weight: 100;
    color: @btn-close-color;
    position: absolute;
    top: 10px;
    right: 10px;
    cursor: pointer;
    z-index: 9999;
    .transition(all .3s ease);

    &:hover {
      color: darken(@btn-close-color, 15%);
      text-decoration: none;
      top: 14px;
      .rotate(180deg);
    }
  }

  .title-modal {
    width: 100%;
    margin-top: 0;
    margin-bottom: 40px;
    font-size: 32px;
  }

  p {
    margin-top: 0;
    margin-bottom: 30px;
    font-size: 13px;
    line-height: 18px;

    strong {
      font-size: 15px;
    }
  }

  @media screen and (max-width: @screen-sm-max) {
    .modal-content {
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;
      padding: 10px;
      .translate(0, 0);

      .slick-modal {
        margin-top: 40px;
      }
    }

    .title-modal {
      font-size: 24px;
    }

    .btn-close {
      right: 0;
    }
  }
}
