
#sobre {
  padding-top: 100px;
  padding-bottom: 100px;
  margin-top: 3px;
  margin-bottom: 40px;
  background: url("@{base-url}img/sobre/banner_sobre.jpg") no-repeat fixed center;
  background-size: cover;
  position: relative;

  &:before {
    content: '';
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    background: rgba(0, 0, 0, 0.7);
    z-index: 1;
  }

  .container-about {
    position: relative;
    z-index: 5;
    margin: 0 auto;
    width: 60%;
  }

  .general-title,
  .general-subtitle,
  p {
    color: @white-clean;
  }

  p {
    max-width: 60%;
    margin-top: 30px;
    display: block;
    font-size: 14px;
    line-height: 20px;
  }

  .continue {
    overflow: hidden;
    height: 0;
    padding-top: 20px;
    .transition(all .3s linear);

    &.continue-opened {
      height: calc(80vh);
      overflow: visible;
    }
    &.transition-completed {
      height: auto;
    }
  }

  .btn {
    margin-top: 20px;

    span:last-of-type {
      display: none;
    }

    &.continue-opened {
      span:first-of-type {
        display: none;
      }

      span:last-of-type {
        display: block;
      }
    }
  }

  @media screen and (max-width: @screen-sm-max) {
    .container-about {
      width: 100%;
      padding-right: 25px;
      padding-left: 25px;
    }

    p {
      max-width: 100%;
    }
  }
}