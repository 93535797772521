
#clientes {
  width: 100%;
  margin-top: 160px;

  .general-title {
    text-align: center;
    margin-bottom: 60px;
  }

  .item-logos {
    text-align: center;

    > div {
      display: block;
      margin-bottom: 40px;
    }
  }

  img {
    display: inline-block;
    vertical-align: middle;
    width: 160px;
    margin: 0 25px;
  }

  .logo-customers-mobile {
    display: none;

    div {
      text-align: center;
    }

    img {
      margin: 0 auto;
      display: inline-block;
      vertical-align: middle;
    }
  }

  @media screen and (max-width: @screen-sm-max) {
    .item-logos {
      > div {
        margin-bottom: 0;
      }
    }

    .logo-customers {
      display: none;
    }

    .logo-customers-mobile {
      display: block;
    }
  }
}