#servicos {
  padding-top: 80px;
  margin-bottom: 80px;

  .list-services {
    display: block;
    margin-top: 20px;
    margin-left: -15px;
    margin-right: -15px;
  }

  .item-services {
    padding-top: 15px;
    padding-bottom: 15px;
    text-decoration: none;

    .inner-item {
      position: relative;
      top: 0;
      padding: 20px 30px;
      background: @white-clean;
      .transition(all .1s linear);
    }

    * {
      color: @general-text;
    }

    &:hover {
      .inner-item {
        top: -3px;
        .box-shadow(0 10px 15px 1px @gray-medium);
      }
    }

    .title-services {
      display: flex;
      align-items: center;
      font-size: 20px;
      height: 50px;

      i {
        font-size: 36px;
      }

      span {
        margin-left: 15px;
      }
    }

    p {
      display: block;
      height: 100px;
      font-size: 13px;
      font-weight: normal;
      line-height: 18px;
    }
  }

  @media screen and (max-width: @screen-sm-max) {
    .list-services {
      margin-left: 0;
      margin-right: 0;
    }
  }
}