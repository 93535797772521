
#projects-page {
  position: relative;

  #home {
    position: relative;
    z-index: 1;
    height: calc(60vh);

    .inner-banner {
      h1 {
        font-size: 60px;
      }

      .title-banner {
        margin-left: 0;
      }
    }
  }

  .content-projects {
    &:extend(.container);

    min-height: 300px;
    position: relative;
    z-index: 5;
    padding: 40px;
    margin-top: -100px;
    margin-bottom: 100px;
    background-color: @white;

    .project-item {
      h4 {
        font-size: 24px;
      }

      &:not(:last-of-type) {
        padding-bottom: 40px;
        margin-bottom: 40px;
        border-bottom: 1px solid @gray-light;
      }
    }

    .images-portfolio {
      margin-top: 40px;

      .fancybox {
        display: block;
        &:extend(.col-md-3);
        height: 260px;
        background: @primary center no-repeat;
        background-size: cover;
        text-decoration: none;
        position: relative;
        cursor: pointer;

        &:not(:last-of-type) {
          border-right: 2px solid @primary;
        }

        &:hover a {
          .opacity(0.6);
        }
      }
    }
  }

  @media screen and (max-width: @screen-sm-max) {
    #home {
      .inner-banner {
        padding-left: 15px;
        padding-right: 15px;

        .title-banner {
          padding-top: 80px;
        }

        h1 {
          font-size: 30px;
        }
      }
    }

    .content-projects {
      margin-top: 0;
      padding-left: 15px;
      padding-right: 15px;
    }
  }
}
