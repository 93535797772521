header.header {
  width: 100%;
  height: 100px;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 99999;
  background: @white-clean;
  border-bottom: 2px solid @primary;
  padding: 10px 0;
  .transition(height .3s ease);

  .container {
    padding-right: 0;
    padding-left: 0;
  }

  .logo {
    max-width: 290px;
    float: left;
    display: block;

    img {
      margin-top: 10px;
    }
  }

  .menu {
    float: right;
    margin-top: 34px;
    position: relative;
    .transition(margin-top .3s ease);

    ul {
      padding: 0;
      margin: 0;
      text-align: center;
    }

    li {
      display: inline-block;
      vertical-align: top;
      margin: 0 15px;

      &:last-of-type {
        margin-right: 0;
      }
    }

    a {
      text-decoration: none;
      color: @general-text;
      font-size: 16px;
      font-weight: 600;
      text-transform: uppercase;
      position: relative;
      display: block;
      letter-spacing: 0.8px;

      span {
        color: @primary;
        font-weight: 600;
      }

      &:hover,
      &.active {
        color: @tertiary;
      }
    }
  }

  &.scrolled {
    height: 80px;

    .logo {
      max-width: 200px;
    }

    .menu {
      margin-top: 23px;
    }
  }

  .hamburger {
    display: none;
    float: right;
    width: 25px;
    margin-top: 20px;
    margin-right: 15px;

    span {
      width: 100%;
      height: 2px;
      background: @gray;
      display: block;
      margin-bottom: 4px;
    }
  }

  @media screen and (max-width: @screen-sm-max) {
    height: 80px;
    padding: 8px 0;

    .logo {
      width: 180px;
      margin-left: 10px;
    }

    .hamburger {
      display: block;
    }

    .menu-items {
      height: 0;
      overflow: hidden;
      .transition(height .3s ease);

      &.menu-opened {
        height: 288px;
      }
    }

    .menu {
      width: 100%;
      margin-top: 20px;

      li {
        text-align: center;
        display: block;
        margin: 0;
      }

      a {
        display: block;
        background: @primary;
        padding: 20px;
        color: @white-clean;
      }
    }
  }
}
