body, html {
  font-size: 14px;
  font-family: @font-base;
  color: @gray;
  background: @white;
  -webkit-text-size-adjust: 100%;
  -ms-text-size-adjust: 100%;
  margin: 0;
  padding: 0;

  * {
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
  }
  *:before,
  *:after {
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
  }
}

article,
aside,
details,
figcaption,
figure,
footer,
header,
main,
menu,
nav,
section,
summary {
  display: block;
}

a {
  .transition(all .3s ease);
  color: @link-color;
  text-decoration: none;
  outline: none;

  &:hover {
    color: @link-hover-color;
    text-decoration: @link-hover-decoration;
  }
}

img {
  max-width: 100%;
}

h1, h2, h3, h4, h5, h6 {
  text-transform: uppercase;
  margin: 10px 0;
  font-family: inherit;
  font-weight: bold;
  color: inherit;
  text-rendering: optimizelegibility;
}

.general-title {
  font-size: 38px;
  line-height: 40px;

  small {
    display: block;
    text-transform: none;
    font-size: 16px;
    line-height: 20px;
  }
}

.general-subtitle {
  font-size: 17px;
  line-height: 19px;
}

.go-up {
  position: fixed;
  right: 20px;
  bottom: 20px;
  .transition(opacity .3s ease);

  .btn {
    padding: 15px 20px;
    font-size: 20px;
  }

  &.hide {
    .opacity(0);
  }
}

.social-icons {
  width: 100px;
  margin: 30px auto 0;
  padding: 0;
  list-style: none;
  text-align: center;

  li {
    display: inline-block;
    vertical-align: middle;
    margin: 0 10px;

    &:first-of-type {
      margin-left: 0;
    }
    &:last-of-type {
      margin-right: 0;
    }
  }

  a {
    display: block;
    color: @general-text;
    background: @white;
    width: 35px;
    height: 35px;
    padding-top: 9px;
    text-align: center;
    font-size: 18px;
    .border-radius(50%);

    &:hover {
      color: @white;
      background: @general-text;
    }
  }
}

@media screen and (max-width: @screen-sm-max) {
  body {
    overflow-x: hidden;
  }

  .go-up {
    right: 5px;
    bottom: 5px;
  }
}