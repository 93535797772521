footer.footer {

  .top-footer {
    background: @quaternary;
    color: @white-clean;
    text-align: center;
    padding-top: 40px;
    padding-bottom: 40px;

    h4 {
      margin: 0 0 20px;
      font-size: 22px;
    }

    address {
      font-style: normal;

      span {
        display: block;
        font-weight: normal;
        margin-bottom: 5px;
      }
    }
  }

  .bottom-footer {
    background: @secondary;
    padding-top: 20px;
    padding-bottom: 20px;
    color: @white-clean;

    .copyright {
      float: left;
      font-weight: normal;

      strong {
        font-weight: 600;
      }
    }

    .developed-by {
      float: right;

      a {
        color: @white-clean;
        text-decoration: none;

        &:hover {
          text-decoration: underline;
        }
      }
    }
  }

  @media screen and (max-width: @screen-sm-max) {
    .bottom-footer {
      padding-top: 40px;
      padding-bottom: 70px;
      text-align: center;

      .copyright {
        float: none;
      }

      .developed-by {
        float: none;
        margin-top: 10px;
        display: block;
      }
    }
  }
}
