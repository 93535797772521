#home {
  width: 100%;
  height: 100%;
  background-size: cover;
  background: @primary no-repeat center;
  position: relative;
  padding-top: 100px;

  .inner-banner {
    width: 80%;
    margin: 0 auto;
    position: absolute;
    top: 50%;
    left: 50%;
    text-align: center;
    .translate(-50%, -50%);

    > * {
      display: inline-block;
      vertical-align: middle;
    }

    img {
      max-width: 180px;
      margin-top: 5px;
    }

    .title-banner {
      text-align: left;
      margin-left: 40px;

      * {
        color: @white-clean;
        margin: 0;
      }

      h1 {
        font-size: 100px;
        font-weight: bold;
        letter-spacing: 5px;
        margin-top: 0;
        margin-bottom: 5px;
      }

      h2 {
        font-size: 24px;
        letter-spacing: 11.5px;
        margin-left: 5px;
      }

      small {
        display: block;
        font-size: 18px;
        font-weight: normal;
        margin-top: 20px;
        letter-spacing: 8.5px;
      }
    }
  }

  .mouse-down {
    width: 35px;
    display: block;
    position: absolute;
    bottom: 60px;
    left: 50%;
    .translate(-50%, 0);
    .animation(mouseMove 1s infinite);

    img {
      max-width: 100%;
    }
  }

  @media screen and (max-width: @screen-sm-max) {
    height: 500px;
    margin-top: 0;
    padding-top: 0;

    .inner-banner {
      width: 100%;
      position: relative;

      img {
        max-width: 60px;
        margin-top: 7px;
      }

      .title-banner {
        margin-left: 10px;

        h1 {
          font-size: 41px;
          margin-bottom: 0;
        }
        h2 {
          font-size: 12px;
          letter-spacing: 3.8px;
        }
        small {
          font-size: 12px;
          line-height: 24px;
          letter-spacing: 4px;
        }
      }
    }

    .mouse-down {
      display: none;
    }

    .particles-js-canvas-el {
      position: relative;
      top: -60px;
    }
  }
}

@keyframes mouseMove {
  from {
    bottom: 60px;
  }
  to {
    bottom: 40px;
  }
}

@-webkit-keyframes mouseMove {
  from {
    bottom: 60px;
  }
  to {
    bottom: 40px;
  }
}