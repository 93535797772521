#portfolio {
  padding-top: 80px;
  margin-bottom: 0;

  .categories-portfolio {
    text-align: left;

    button {
      margin: 5px 3px;
    }
  }

  .items-portfolio {
    width: 100%;
    margin-top: 60px;
    text-align: center;

    .item-portfolio {
      &:extend(.col-md-3);
      height: 300px;
      padding-right: 0;
      padding-left: 0;

      .inner-item-portfolio {
        display: inline-block;
        vertical-align: top;
        position: relative;
        width: 100%;
        height: 100%;

        &:hover a > div {
          .opacity(0);
        }
      }

      a {
        display: block;
        width: 100%;
        height: 100%;
        background: @primary center no-repeat;
        background-size: cover;
        text-decoration: none;
        position: relative;

        div {
          width: 100%;
          height: 100%;
          display: flex;
          position: absolute;
          top: 0;
          left: 0;
          background: @primary;
          align-items: center;
          justify-content: center;
          .opacity(0.9);
          .transition(opacity .3s linear);

          span {
            font-size: 30px;
            color: @white-clean;
          }
        }
      }
    }
  }

  @media screen and (max-width: @screen-sm-max) {
    .items-portfolio {
      .item-portfolio {
        width: 100%;
        margin-bottom: 2px;
        margin-left: 0;
      }
    }
  }
}