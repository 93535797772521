#contato {
  padding-top: 80px;
  margin-bottom: 80px;

  .contact-container {
    margin-top: 40px;
  }

  .btn {
    width: 100%;
    display: block;
    .transition(width .3s linear);
  }

  .contacts {
    font-style: normal;
    font-size: 18px;
    margin-top: 40px;
    margin-bottom: 40px;

    &:after {
      content: '';
      width: 200px;
      height: 1px;
      display: block;
      margin-top: 20px;
      background: @gray-light;
    }

    i:first-of-type {
      margin-right: 5px;
    }

    > div {
      display: flex;
      align-items: center;
      justify-content: flex-start;
      margin-bottom: 10px;

      div {
        width: 50px;
      }
      span {
        width: 70%;
      }
    }

    span {
      text-align: left;
    }
  }

  .social-icons {
    margin-top: 20px;
    margin-left: 0;
    margin-right: 0;

    a {
      color: @white-clean;
      background: @primary;

      &:hover {
        color: @white-clean;
        background: @tertiary;
      }
    }
  }

  .side-contact {
    padding-left: 60px;
  }

  .recaptch-needed {
    display: none;

    &.recaptch-error {
      display: block;
    }

    .error {
      color: @brand-danger;
      font-size: 12px;
    }
  }

  .loading-form {
    .btn {
      width: 80%;
      float: left;
    }
  }

  @media screen and (max-width: @screen-sm-max) {
    .col-md-6.side-contact {
      margin-top: 60px;
      padding-left: 25px;
    }
  }
}