
.slick-slide {
  outline: none;
}

.slick-dots {
  width: 100%;
  text-align: center;
  padding: 0;
  margin: 30px 0 0;

  li {
    display: inline-block;
    vertical-align: middle;
    margin: 0 8px;
  }

  button {
    width: 5px;
    height: 5px;
    padding: 0;
    text-indent: -10000px;
    background: @primary;
    border: 0;
    position: relative;
    outline: none;
    cursor: pointer;
    .border-radius(50%);
    .transition(all .2s ease);
  }

  .slick-active button {
    width: 8px;
    height: 8px;
    top: -2px;
  }
}

.slick-arrow {
  position: absolute;
  top: 45%;
  width: 30px;
  height: 50px;
  border: 0;
  background: @black;
  color: @white-clean;
  font-size: 18px;
  font-weight: 100;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 999;
  text-align: center;
  outline: none;
  cursor: pointer;
  .translate(0, -50%);

  &.slick-prev {
    left: 0;
  }
  &.slick-next {
    right: 0;
  }
}