
.btn {
  font-size: 13px;
  font-weight: 300;
  text-transform: uppercase;
  padding: 16px 28px;
  border: 0;
  color: @white;
  display: inline-block;
  background-color: @primary;
  .border-radius(28px);
  .transition(all .3s ease);
  text-decoration: none;
  outline: none;
  cursor: pointer;

  &:hover {
    background: @tertiary;
    color: @white;
    text-decoration: none;
  }

  &.btn-secondary, &.mixitup-control-active {
    background: @tertiary;

    &:not(.mixitup-control-active):hover {
      background: @primary;
    }

    &.mixitup-control-active {
      cursor: default;
    }
  }

  &.btn-light {
    background: @white;
    color: @general-text;
    font-weight: 600;

    &:hover {
      background: @tertiary;
      color: @white;
    }
  }

  &.btn-subscribe {
    color: @tertiary;
  }

  &.btn-smaller {
    padding: 8px 20px 6px;
    font-size: 11px;
  }

  &[disabled] {
    .opacity(0.8) !important;
    background-color: @primary !important;
    color: @title-steps !important;
  }

  @media screen and (max-width: @screen-sm-max) {
    text-align: center;
  }
}

.btn-play {
  position: absolute;
  bottom: 60px;
  left: 60px;
  font-size: 11px;
  letter-spacing: 1px;
}

.play {
  background: url("@{base-url}img/icon-play.png") no-repeat;
  width: 16px;
  height: 18px;
  display: inline-block;
  position: relative;
  top: 5px;
  margin: 0 5px;
}