
.form-group {
  margin-bottom: 15px;
  position: relative;

  &:before,
  &:after {
    content: '';
    display: table;
    clear: both;
  }
}

.form-control {
  width: 100%;
  min-height: 40px;
  border: 1px solid @gray-light;
  padding: 18px 15px 14px;
  color: @general-text;
  outline: none;

  &.error {
    border-color: @brand-danger;
  }
}

label.error {
  color: @brand-danger;
  margin: 5px;
  display: block;
  font-size: 12px;
}

.label {
  position: absolute;
  top: 19px;
  left: 12px;
  color: @general-text;
  .transition(all .2s ease);

  span {
    color: @primary;
    position: relative;
    top: -1px;
    margin-right: 2px;
  }
}

textarea.form-control {
  resize: none;
  min-height: 150px;
}

.select-custom {
  position: relative;

  &.error {
    border-color: @primary;
    margin-bottom: 10px;
  }

  &:after {
    content: '';
    width: 24px;
    height: 24px;
    background: url("@{base-url}img/icon-down.png") no-repeat;
    position: absolute;
    top: 8px;
    right: 10px;
    z-index: 12;
  }

  select {
    cursor: pointer;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 20;
    padding: 0 15px;
    width: 100%;
    height: 100%;
    border: none;
    box-shadow: none;
    background: transparent;
    background-image: none;
    -webkit-appearance: none;
    color: @general-text;
    .border-radius(0);

    span {
      color: @primary;
    }

    &:focus {
      outline: none;
    }
  }

  &.required {
    select {
      padding-left: 19px;
    }

    &:before {
      content: '*';
      position: absolute;
      top: 12px;
      left: 10px;
      color: @primary;
    }
  }

  label.error {
    position: absolute;
    bottom: -20px;
    left: 0;
  }

  @media screen and (max-width: @screen-sm-max) {
    select {
      width: 100%;
      padding-right: 35px;
    }
  }
}

.container-messages {
  width: 100%;
  text-align: center;
  font-size: 14px;
  margin-bottom: 15px;

  span {
    vertical-align: middle;
    display: block;
    overflow: hidden;
    padding: 0;
    height: 0;
    .opacity(0);
    .transition(all .3s ease);
  }

  .show {
    padding: 13px;
    .opacity(1);
    .border-radius(8px);

    &.success-message {
      height: 40px;
    }
    &.error-message {
      height: 60px;
    }
  }

  .success-message {
    color: @success-color;
    background: lighten(@success-color, 50%);
  }
  .error-message {
    color: @primary;
    background: lighten(@primary, 50%);
  }
}

.focused-field {
  .label {
    font-size: 8px;
    top: 6px;
  }
}